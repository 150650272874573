// Override Bootstrap's variables before importing

// Colors
$primary: #e9cfbc;
$body-bg: #343652;
$body-color: $primary;
$content-bg-color: #585a7c;
$secondary: #ffe1cc;
$red: #de3155;

// Jumbotron
$jumbotron-bg: $primary;

// Misc
$border-radius: 15px;
$border-radius-lg: 20px;
$border-radius-sm: 11px;

// Type
$font-family-sans-serif: "Roboto", sans-serif;
$headings-margin-bottom: 0.5em;

$hlcolor: #ff7300;

$link-hover-color: $hlcolor;

$navbar-dark-color: $primary;
$navbar-dark-hover-color: $hlcolor;
$navbar-dark-active-color: $hlcolor;

// Cards
$card-bg: $content-bg-color;
$card-spacer-x: 1.25rem;
$card-spacer-y: 0.65rem;
$card-border-width: 0;

// Modal
$modal-content-bg: $body-bg;
$modal-header-border-width: 0;

// Close
$close-color: $secondary;
$close-font-weight: 400;
$close-text-shadow: none;

// Buttons
$input-btn-font-size: 14px;
$btn-font-weight: 900;
$btn-border-radius: 5px;
$btn-box-shadow: inset 0px 4px 10px #5a4d44;
$btn-padding-y: 0.22rem;
$btn-padding-x: 0.95rem;

// Other

// Then import Bootstrap
@import "node_modules/bootstrap/scss/bootstrap";

// Cards
.card {
  margin-top: 20px;
}

.card-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  display: inline-block;
}

.card-subtitle {
  display: inline-block;
  padding-left: 6px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
}

.card.hl-card {
  background: $hlcolor;
  color: $white;
}

.btn-secondary {
  color: $body-bg;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active {
  background-color: $hlcolor;
  color: $body-bg;
  border-color: $hlcolor;
}

.btn {
  box-shadow: 0px 4px 10px #5a4d44;
}
.btn.shadow-lite {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

// Modal
.modal-content {
  .modal-header {
    .modal-title {
      width: 100%;
      text-align: center;
      padding-left: 30px;
    }
  }
  .modal-body {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }

  .modal-footer {
    justify-content: center;
  }
}

.modal-content {
  .modal-header {
    .modal-title {
      width: 100%;
      text-align: center;
      padding-left: 30px;
    }
  }
  .modal-body {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }

  .modal-footer {
    justify-content: center;
  }
}

.no-close {
  .modal-content {
    .modal-header {
      .modal-title {
        padding-left: 0;
      }
    }
  }
}
