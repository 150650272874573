/**
 * Styles for the Header.js component
 */

header {
  .header-bg {
    max-width: 100%;
  }

  position: relative;
  margin-bottom: 65px;

  .navbar {
    background-color: $content-bg-color;
    position: absolute;
    width: 100%;
    left: 0;
    top: auto;
    z-index: 9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  @include media-breakpoint-up(xs) {
    .navbar {
      height: auto;
    }
  }

  @include media-breakpoint-up(md) {
    .navbar {
      height: 65px;
    }
  }

  .navbar-expand-md .navbar-nav .nav-item.active {
    position: relative;
  }

  .navbar-expand-md .navbar-nav .nav-item.active::after {
    content: '';
    width: 0px;
    height: 0px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 11px solid $content-bg-color;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -24px;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1.3rem;
    padding-left: 1.3rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;

    .my-money-ico {
      height: 20px;
      display: inline-block;
      margin-left: 5px;
      vertical-align: top;
    }
  }
}
