/**
 * Styles for the Footer.js component
 */

footer {
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;

  a {
    color: #E9CFBC;
    display: inline-block;
    margin-right: 25px;
  }

  a:hover {
    text-decoration: none;
  }
}
