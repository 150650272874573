.create-game-card {

  .create-game {

    &-info-box {
      > span {
        display: inline-block;
        font-weight: bold;
        font-size: 12px;
        padding-right: 12px;

        img {
          width: 35px;
        }
      }

      margin-top: -15px;
      padding-bottom: 17px;
    }

    &-button {
      height: 50px;

      img {
        width: 15px;
      }
    }

    &-content {
      padding-top: 15px;
    }

    &-level {
      label {
        width: 100%;
        padding-left: 33px;
        margin-bottom: 15px;
      }

      &-name {
        position: relative;
        background: $white;
        width: 100%;
        height: 35px;
        display: inline-block;
        font-size: 12px;
        padding-left: 5px;
        color: $body-bg;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        line-height: 32px;

        .mc-badge {
          position: absolute;
          right: -20px;
          top: 0;
          bottom: 0;
          margin: auto;

          img {
            width: 34px;
          }
        }
      }

      &-name::before {
        content: "";
        position: absolute;
        width: 4px;
        height: 100%;
        left: -8px;
        top: 0;
        background: $white;
      }
    }
  }
}
