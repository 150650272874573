.awards-content {
  height: 100%;

  .card {
    max-height: calc(100% - 35px);
    min-height: calc(100% - 35px);

    background-image: url("../../../images/menu/awards-bg.png");
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 60% 60%;

    .termo-box {
      flex-direction: row;
      padding-top: 4px;

      span {
        display: inline-block;
        width: auto;
      }
      .termo-wrapper {
        margin-left: 20px;
        width: auto;
        max-width: 250px;
        display: inline-block;
        background: $content-bg-color;
      }
    }

    .awards-title {
      font-size: 18px;
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .awards-row {
      margin-bottom: 35px;

      > div {
        display: inline-block;
        width: 20%;
        text-align: center;
        vertical-align: top;

        img {
          max-height: 110px;
          min-height: 110px;
          height: 110px;
        }

        .award-name {
          font-weight: bold;
          font-size: 16px;
          line-height: 18px;
          display: inline-block;
          color: $secondary;
          width: 100%;
        }
        .award-level {
          display: inline-block;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: $secondary;
          width: 100%;
        }
      }
    }

    .awards-row-tour {
      > div {
        width: 20%;
      }
    }
  }
}
