.top {
  &-content {
    position: relative;

    .top-20-card-alltime {
      background: #A3223D;
    }
  }

  &-buttons {
    .row {
      padding-bottom: 10px;
    }

    &-foa-col-1,
    &-foa-col-2 {
      button {
        padding: 0.22rem 0.65rem;
      }
    }

    &-foa-col-1 {
      padding-right: 4px;
    }

    &-foa-col-2 {
      padding-left: 4px;
    }

    button {
      font-size: 13px;
      //white-space: nowrap;
      width: 100%;
    }

    button.active {
      background: $hlcolor;
    }
  }
}
