$incices-margin: 30px;

.game-container {
  display: flex;
  height: 100%;
  flex-grow: 1;
  flex-direction: row;
  padding-bottom: 20px;

  //.game-main-container {
  //display: flex;
  //flex-direction: row;
  //flex-grow: 1;
  //padding-bottom: 20px;

  #selected-piece {
    z-index: 99;
  }

  #game-board {
    position: relative;
  }

  .game {
    &-fake-timer {
      margin-top: 15px;
      text-align: center;
      flex-shrink: 1;

      img {
        width: 80%;
      }
    }

    &-player-info-box {
      text-align: center;

      > img {
        width: 70%;
        border-radius: 50%;
      }

      > span {
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        display: inline-block;
        margin-top: 10px;
      }
    }

    &-win-losse-koef {
      > span {
        display: block;
        width: 100%;
        font-size: 14px;
        white-space: nowrap;
      }
    }

    &-left-col {
      flex: 0 1 15%;
    }
    &-center-col {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
    &-right-col {
      flex: 0 1 15%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    &-board-container {
      margin-top: 20px;
      flex-grow: 1;

      &-wrapper {
        padding-left: $incices-margin;
        padding-bottom: $incices-margin;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
      }
    }

    &-board {
      &-piece-container {
        width: 100%;
        height: 100%;
        z-index: 99;
        position: relative;
      }

      &-row {
        height: 12.5%;
        position: relative;
      }

      &-row-indice {
        display: inline-block;
        position: absolute;
        left: -$incices-margin;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 15px;
        text-align: center;
      }

      &-col-indice-row {
        position: absolute;
        bottom: -$incices-margin;
        width: 100%;
      }

      &-col-indice {
        width: 12.5%;
        display: inline-block;
        text-align: center;
      }

      &-row:nth-child(odd) {
        .game-board-cell:nth-child(odd) {
          background: #ad967c;
        }
        .game-board-cell:nth-child(even) {
          background: #f9dec0;
        }
      }

      &-row:nth-child(even) {
        .game-board-cell:nth-child(odd) {
          background: #f9dec0;
        }
        .game-board-cell:nth-child(even) {
          background: #ad967c;
        }
      }

      &-cell {
        height: 100%;
        width: 12.5%;
        display: inline-block;
        position: relative;

        &-move {
          background: #bc7870 !important;
          cursor: pointer;
        }

        img {
          max-width: 90%;
          max-height: 90%;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }

        img.game-board-piece-selected {
          max-width: 50%;
          max-height: 50%;
          cursor: pointer;
        }

        img.game-board-piece-active {
          cursor: pointer;
        }
      }
    }

    &-center-tools {
      flex-shrink: 1;
      text-align: center;
    }
  }

  .game-moves-list {
    min-height: 0;
    flex: 1;
    margin-top: 5px;

    .card-body {
      display: flex;
      flex-direction: column;
    }

    ul {
      padding: 0;
      margin: 0;
      overflow: auto;

      li {
        display: flex;
        list-style: none;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 17px;
      }
    }
  }
  //}

  .game-buttons-container {
    padding-bottom: 20px;
    flex-shrink: 1;
    text-align: center;
  }
}

.game-timer {
  position: relative;
  display: flex;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  align-content: center;

  #timerText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 50px;
    line-height: 55px;
  }
}
