.profile-card {
  margin-top: 5px;
}

.profile-box {
  &-foto {
    position: relative;
    display: inline-block;

    img {
      max-width: 80px;
      border-radius: 50%;
    }

    .mc-badge {
      position: absolute;
      right: -12px;
      bottom: -12px;
      display: inline-block;

      img {
        max-width: 30px;
      }
    }
  }

  &-row {
    padding-bottom: 8px;
    text-align: left;

    &-basics {
      padding-bottom: 15px;
    }

    &-stats {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: -5px;
      margin-left: -5px;

      > div {
        padding-right: 5px;
        padding-left: 5px;
      }

      img {
        width: 35px;
        height: 35px;
        display: inline-block;
        margin-right: 6px;
      }

      span {
        font-size: 18px;
        line-height: 20px;
      }
    }

    &-buttons {
      padding-top: 15px;
      text-align: center;
    }
  }

  &-name {
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }

  &-name-compact {
    padding-left: 20px;
  }
}

// Termometer

.termo-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 5px;
  width: 100%;
  padding-top: 30px;

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    display: inline-block;
    width: 100%;
    text-align: center;
  }
}

.termo {
  &-wrapper {
    display: block;
    background: $white;
    height: 100%;
    min-height: 0;
    position: relative;
    overflow: hidden;
    background: $body-bg;
    width: 80%;

    img {
      z-index: 9;
      position: relative;
      width: 500px;
      margin-left: -3px;
      max-width: 100%;
    }
  }

  &-bar {
    top: 10%;
    width: 100%;
    height: 80%;
    background: #81bf40;
    display: inline-block;
    position: absolute;
    z-index: 1;

    &-bg {
      top: 10%;
      width: 90%;
      height: 80%;
      background: white;
      display: inline-block;
      position: absolute;
      z-index: 1;
    }
  }
}

// Day bonus modal

.day-bonus-modal {
  .modal-body {
    p {
      margin-bottom: 0;
      font-size: 16px;
    }

    .day-bonus-circle {
      background: $content-bg-color;
      border-radius: 50%;
      width: 200px;
      height: 200px;
      text-align: center;
      line-height: 200px;
      margin-bottom: 15px;

      img {
        width: 85px;
        vertical-align: middle;
      }

      span {
        font-size: 85px;
        vertical-align: middle;
        color: $secondary;
      }
    }
  }
}

// How to play

.how-to-play-content {
  height: 100%;
  min-height: 600px;
  max-height: 600px;

  .how-to-play-col {
    max-height: 100%;

    .card {
      max-height: calc(100% - 35px);
      min-height: calc(100% - 35px);

      .card-body {
        display: flex;
        max-height: inherit;

        .tab-content {
          max-height: inherit;
          overflow: auto;
        }
      }
    }
  }
}

// Top stuff

.top-10-card,
.top-20-card {
  &-plus {
    background: $hlcolor;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    font-weight: bold;
    font-size: 12px;
    line-height: 30px;
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    color: $secondary;
  }

  &-plus-wrap {
    flex-grow: 1;
    justify-content: flex-end;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;    

    li {
      display: flex;
      flex-direction: row;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      align-items: center;
      padding-bottom: 10px;

      > span, img {
        display: flex;
      }

      img {
        width: 45px;
        border-radius: 50%;
        margin-right: 10px;
      }

      span {
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }
}
