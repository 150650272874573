.game-list {

  &-content {
    margin-left: -$card-spacer-x;
    margin-right: -$card-spacer-x;

    > div {
      padding-right: 0;
      padding-left: 0;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    overflow-x: hidden;
    max-height: 500px;
  }

  &-el {
    padding: 7px 15px;
    background-color: $body-bg;
    margin-bottom: 7px;

    &-buttons {
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        vertical-align: middle;
      }
    }

    img {
      max-width: 55px;
      border-radius: 50%;
      margin-right: 10px;
    }

    &-joined {
      background-color: $hlcolor !important;
      color: #ffe1cc;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
    }
  }

  /*&-el:nth-child(even) {
    background-color: $body-bg;
  }*/
}
